import {
    SALE_LIST,
    SEARCH_FORM_DATA,
      } from "../constant/types";
    
    // LOGIN ACTION
    export const  SaleList = (data:any) => ({
        
      type: SALE_LIST,
      payload: data,
    }
    );

    export const  SearchFormData = (data:any) => ({
        
      type: SEARCH_FORM_DATA,
      payload: data,
    }
    );
    
  
  
    export default SaleList;
  