import axios, { AxiosError } from 'axios';
import swal from 'sweetalert';

export const putBulkUpdate = async (payload: any) => {
    let url: string = `${process.env.REACT_APP_CONTRACT_URL}/contracts/bulkUpdate`;
    const requestOptions: any = {
        headers: {
            "Content-type": "application/json",
        },
        timeout: 60000,
    };

    try {
        const putBulkUpdateResponse = await axios.put(url, payload, requestOptions);

        if (putBulkUpdateResponse.status === 200) {
            return putBulkUpdateResponse.data;
        } else {
            handleErrorResponse("Unexpected status code: " + putBulkUpdateResponse.status);
        }
    } catch (error:any) {
        if (axios.isAxiosError(error)) {
            if (error.code === 'ECONNABORTED') {
                handleErrorResponse("Request timed out");
            } else {
                handleErrorResponse("Network error occurred");
            }
        } else {
            handleErrorResponse("Error: " + "Update Failed");
        }
        console.error("Error in putBulkUpdate:", error);
        return null;
    }
};

const handleErrorResponse = (message: string) => {
    swal({
        text: "Bulk Update Failed: " + message,
        icon: "error",
    });
};
