import { SET_CONSORTIUM_NAME_SEARCH, SET_CONSORTIUM_SEARCH_LIST } from "../../../bookingConfirmation/storeConstant"


const initialState = {
    consortiumName: "",
    consortiumList: []
}

const consortiumReducer = (state = initialState , action: any) => {
    switch(action.type){
        case SET_CONSORTIUM_NAME_SEARCH:
            return {
                ...state,
                consortiumName: action.payload
            }
        case SET_CONSORTIUM_SEARCH_LIST:
            return {
                ...state,
                consortiumList: action.payload
            }
        default:
            return state 

    }

}

export default consortiumReducer;