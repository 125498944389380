import React, { useEffect, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Box, Checkbox, FormControlLabel, Radio, Tab, Button, MenuItem, Select } from '@mui/material';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import icon_child from '../../_theme/_assets/images/icon__child.svg';
import icon_adult from '../../_theme/_assets/images/icon_adult.svg';
import icon_childfriendly from '../../_theme/_assets/images/icon_sharp-child-friendly.svg';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getOneTarif } from '../thunks/getOneTarif';
import { useHistory } from 'react-router-dom';
import { putBulkUpdate } from '../thunks/putBulkUpdate';
import swal from 'sweetalert';


const NewPrix = () => {
    const history = useHistory();
    const tarifData = useSelector((state) => state.commonDataReducer.tarifData);
    const classData = useSelector((state) => state.commonDataReducer.classData);
    const prixDetails = useSelector((state)=>state.commonDataReducer.prixDetail)
    const [disableEdit, setDisableEdit] = useState(true)
    const [prixDetailsLocal, setPrixDetailsLocal] = useState({})
    const [updateLoader, setUpdateLoader] = useState(false)
    const [tarifValues, setTarifValues]= React.useState({});
    useEffect(()=>{
        // setPrixDetailsLocal({...prixDetails})
        let temp = prixDetails?.tourCode
        let combinedObj = {}
        temp.forEach(({marketCode, tourCodes, passengerType}) => {
            if(combinedObj[marketCode]){
                combinedObj[marketCode]["passengerType"].push(passengerType);
            }else{
                combinedObj[marketCode] = {
                    tourCodes,
                    marketCode,
                    passengerType: [passengerType]
                }
            }
        })
        let localprixDetailsLocal = {...prixDetails}
        localprixDetailsLocal['tourCode'] = Object.values(combinedObj)
        setPrixDetailsLocal(localprixDetailsLocal)
        
    },[])
    
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue)
    };

    useEffect(async()=>{
        if(tarifData?.tarifKey){
            let data=await getOneTarif(tarifData.tarifKey);
            if(data.data){
                setTarifValues(data.data);
            }
        }

    },[tarifData])

    const commonOnChange = (key, newValue) => {
        setPrixDetailsLocal(prevData => ({
            ...prevData,
            [key]: newValue
        }));
    };
    
    const blackOutDates = {
        fromDate: null,
        toDate: null,
        aller: undefined,
        retour: undefined,
        exceptionType: ''
    }

    const blackOutDays = {
        day: '',
        aller: undefined,
        retour: undefined,
        exceptionType: ''
    }

    const blackOutCarrier = {
        carrierCode: '',
        flightNumberFrom: '',
        flightNumberTo: '',
        aller: undefined,
        retour: undefined,
        exceptionType: ''
    }

    const tourCode = {
        tourCodes: "",
        marketCode: "",
        passengerType: ""
    }

    const savePrix = async() => {
        setUpdateLoader(true)
        const payload = {
            "prixKeys": prixDetailsLocal?.prixKey ? [prixDetailsLocal?.prixKey] : undefined,
            "prixData" : removeNullProperties(prixDetailsLocal)
        }
        // const finalPrixData = filterObject(convertPropertiesToArray(payload)?.prixData, keysToKeep)
        // const finalPayload = {...payload,["tarifData"]:finalTarifData}
        const bulkUpdateResponse = await putBulkUpdate(payload)
        setUpdateLoader(false)
        if(bulkUpdateResponse?.data?.message != "Bulk update was successful"){
            swal({
              text:bulkUpdateResponse?.data?.message,
              icon: "error",
              button: "Close",
            })
        }else{
            swal({
                text:"Tarif updated successfully!",
                icon: "success",
                button: "Close",
              })
        }
    }

    const removeNullProperties = (obj) => {
        const cleanedObj = {};
        for (const key in obj) {
            if (obj[key] !== null && obj[key] !== undefined) {
                cleanedObj[key] = obj[key];
            }
        }
        return cleanedObj;
    }

    return (
        <>
            <div className="cm-head-2 mb-8">
                <div className="d-flex justify-content-between">
                    <span style={{"fontSize":"1rem","color":"#0055b8","cursor":"pointer"}} onClick={()=>{history.push('/newcontractlist')}}>{`<<- Back to Results`}</span>
                    <span className="cm__Title">Prix Edit</span>
                    <span>
                        {/* <button className='classEditBtn' onClick={()=>setDisableEdit(!disableEdit)}> */}
                            <i className="fas fa-edit" style={{ "color": "#0055b8", "cursor":"pointer" }} onClick={()=>setDisableEdit(!disableEdit)}></i>
                        {/* </button> */}
                    </span>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <div className="classTopEditSetion">
                        <div className="classTopField">
                            <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label" >Tariff key</label>
                                </div>
                                <label style={{"cursor": "pointer"}} onClick={()=>history.push('/newTariff')}>{tarifData?.tarifKey}</label>
                            </div>
                            <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label">Carrier</label>
                                </div>
                                <label>{tarifData?.carrier}</label>
                            </div>
                            <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label">Origin</label>
                                </div>
                                <label>{tarifData?.origins}</label>
                            </div>
                            <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label">Destination</label>
                                </div>
                                <label>{tarifData?.destinations}</label>


                            </div>
                            <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label">Cabin Type</label>
                                </div>
                                <label>{tarifValues.cabinType?.join('\n')}</label>

                            </div>
                            <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label">Trip Type</label>
                                </div>
                                <label>{tarifValues?.tripType == "OW"? "One Way" : "Not One Way"}</label>


                            </div>
                            <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label">Branded Fare</label>
                                </div>
                                <label>{tarifData?.isBrandedFare == 1 ? "Yes" : "No"}</label>

                            </div>
                            <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label">Contract Type</label>
                                </div>
                                <label>{tarifValues.cruiseFare == 1 ? "Cruise" : tarifValues.landFare == 1 ? "Land" : "Air Only"}</label>


                            </div>
                        </div>
                        <div className="classTopEdit">
                            <button className='classEditBtn'>
                                <i className="fas fa-edit" onClick={()=>history.push('/newTariff')}></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div className='col-md-12'>
                    <div className="classTopEditSetion PrixTopBorder">
                        <div className="classTopField">
                            <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label">Class key</label>
                                </div>
                                <label style={{"cursor": "pointer"}} onClick={()=>history.push('/newClass')}>{classData?.clasKey}</label>
                            </div>
                            <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label">Classes</label>
                                </div>
                                <label>{classData?.classCodes?.join(', ')}</label>
                            </div>
                            <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label">Brand</label>
                                </div>
                                <label>{classData?.brandCode}</label>
                            </div>
                            {/* <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label">Destination</label>
                                </div>
                                <label>000, 001</label>
                            </div> */}
                            <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label">Fare Type</label>
                                </div>
                                <label>{classData?.contractType}</label>

                            </div>
                            <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label">Min / Max</label>
                                </div>
                                <label>{classData?.minmaxStay}</label>


                            </div>
                            <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label">Ticketing Fee</label>
                                </div>
                                <label>{classData?.ticketingFee}</label>

                            </div>
                            <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label">EDR Date</label>
                                </div>
                                <label>{moment.utc(classData?.classLastTicketingDate).format("YYYY-MM-DD")}</label>


                            </div>
                        </div>
                        <div className="classTopEdit">
                            <button className='classEditBtn'>
                                <i className="fas fa-edit" onClick={()=>history.push('/newClass')}></i>
                            </button>
                        </div>
                    </div>
                </div>



            </div>

            <div className="Tariff__Section Prix__section">
                <div >
                    <div className="card Tariff__BorderB">
                        <div className="card-body">
                            <div className="row mt-4">
                                <div className="col-md-6 cm-col">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="cm-head-label">Valid From</label>

                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker className='BulkUpdate__DatePicker' format="dd MMM yyyy" value={moment(new Date(prixDetailsLocal?.validFrom)).format("DD-MMM-yyyy")} onChange={(e) => {commonOnChange('validFrom',new Date(e).toISOString().split('.')[0] + '.000Z');}} disabled={disableEdit}/>
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="cm-head-label">Valid To</label>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker className='BulkUpdate__DatePicker' format="dd MMM yyyy" value={moment(new Date(prixDetailsLocal?.validTo)).format("DD-MMM-yyyy")} onChange={(e) => {commonOnChange('validTo',new Date(e).toISOString().split('.')[0] + '.000Z');}} disabled={disableEdit}/>
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 cm-col">

                                    <div className="row">
                                        <div className="col-md-6">

                                            <label className="cm-head-label">Last Ticketing Date</label>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker className='BulkUpdate__DatePicker' format="dd MMM yyyy" value={moment(new Date(prixDetailsLocal?.prixLastTicketingDate)).format("DD-MMM-yyyy")} onChange={(e) => {commonOnChange('prixLastTicketingDate',new Date(e).toISOString().split('.')[0] + '.000Z');}} disabled={disableEdit}/>
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="cm-head-label">Last Return Date</label>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker className='BulkUpdate__DatePicker' format="dd MMM yyyy" value={moment(new Date(prixDetailsLocal?.prixLastReturnDate)).format("DD-MMM-yyyy")} onChange={(e) => {commonOnChange('prixLastReturnDate',new Date(e).toISOString().split('.')[0] + '.000Z');}} disabled={disableEdit}/>
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6 cm-col">
                                            <label className="cm-head-label">Prix Note</label>
                                            <input className="form-control cm-input" placeholder="Enter Note" value={prixDetailsLocal?.prixNote} onChange={(e) => {commonOnChange('prixNote',e?.target?.value)}} disabled={disableEdit}/>
                                        </div>
                                        <div className="col-md-6 cm-col">
                                            <label className="cm-head-label"></label>
                                            <FormControlLabel control={<Checkbox checked={Boolean(prixDetailsLocal?.noTKTBY)} onChange={()=>commonOnChange('noTKTBY',!Boolean(prixDetailsLocal?.noTKTBY))}/>} label="No TKT-BY" disabled={disableEdit}/>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 20, borderColor: '#E1F0FF', background: '#fff' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Prix" value="1" />
                                    <Tab label="Exception" value="2" />
                                    <Tab label="Emission (Segments)" value="3" />
                                </TabList>
                            </Box>
                            <TabPanel value="1" className='m-0 p-0'>
                                <div className="card Tariff__BorderB">
                                    <div className="card-body">
                                        <div className="cm-head-2 mb-4">
                                            <div className="d-flex justify-content-between">
                                                <span className="">Prix</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-4 prix_iconBox">
                                                        <img src={icon_adult} />
                                                    </div>
                                                    <div className="col-md-8">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label">OUR COMMISSION</label>
                                                            <input className="form-control cm-input" type="text" placeholder='Enter %' value={prixDetailsLocal?.commissionPercentage} onChange={(e) => {commonOnChange('commissionPercentage',e?.target?.value)}} disabled={disableEdit}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="row">

                                                    <div className="col-md-8">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label">OUR MARKUP</label>
                                                            <input className="form-control cm-input" type="number" placeholder='Enter $' value={prixDetailsLocal?.markup} onChange={(e) => {commonOnChange('markup',e?.target?.value)}} disabled={disableEdit}/>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-10">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label"> AGENCY COMMISSION</label>
                                                            <input className="form-control cm-input cm__inputW80" type="text" placeholder='Enter %' value={prixDetailsLocal?.passBackCommission} onChange={(e) => {commonOnChange('passBackCommission',e?.target?.value)}} disabled={disableEdit}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label">MIN SELL</label>
                                                            <input className="form-control cm-input" type="text" placeholder='Enter $' value={prixDetailsLocal?.minumumSellingPrice} onChange={(e) => {commonOnChange('minumumSellingPrice',e?.target?.value)}} disabled={disableEdit}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card Tariff__BorderB">
                                    <div className="card-body">
                                        <div className="cm-head-2 mb-4">
                                            <div className="d-flex justify-content-between">
                                                <span className="">Prix</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-4 prix_iconBox">
                                                        <img src={icon_child} />
                                                    </div>
                                                    <div className="col-md-8">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label">OUR COMMISSION</label>
                                                            <input className="form-control cm-input" type="text" placeholder='Enter %' value={prixDetailsLocal?.childCommissionPercentage} onChange={(e) => {commonOnChange('childCommissionPercentage',e?.target?.value)}} disabled={disableEdit}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="row">

                                                    <div className="col-md-8">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label">OUR MARKUP</label>
                                                            <input className="form-control cm-input" type="text" placeholder='Enter $' value={prixDetailsLocal?.childMarkUp} onChange={(e) => {commonOnChange('childMarkUp',e?.target?.value)}} disabled={disableEdit}/>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-10">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label"> AGENCY COMMISSION</label>
                                                            <input className="form-control cm-input cm__inputW80" type="text" placeholder='Enter %' value={prixDetailsLocal?.childPassBackCommission} onChange={(e) => {commonOnChange('childPassBackCommission',e?.target?.value)}} disabled={disableEdit}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label">MIN SELL</label>
                                                            <input className="form-control cm-input" type="text" placeholder='Enter $' value={prixDetailsLocal?.minumumSellingPrice} onChange={(e) => {commonOnChange('minumumSellingPrice',e?.target?.value)}} disabled={disableEdit}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card Tariff__BorderB">
                                    <div className="card-body">
                                        <div className="cm-head-2 mb-4">
                                            <div className="d-flex justify-content-between">
                                                <span className="">Prix</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-4 prix_iconBox">
                                                        <img src={icon_childfriendly} />
                                                    </div>
                                                    <div className="col-md-8">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label">OUR COMMISSION</label>
                                                            <input className="form-control cm-input" type="text" placeholder='Enter %' value={prixDetailsLocal?.infantCommissionPercentage} onChange={(e) => {commonOnChange('infantCommissionPercentage',e?.target?.value)}} disabled={disableEdit}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="row">

                                                    <div className="col-md-8">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label">OUR MARKUP</label>
                                                            <input className="form-control cm-input" type="text" placeholder='Enter $' value={prixDetailsLocal?.infantMarkUp} onChange={(e) => {commonOnChange('infantMarkUp',e?.target?.value)}} disabled={disableEdit}/>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-10">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label"> AGENCY COMMISSION</label>
                                                            <input className="form-control cm-input cm__inputW80" type="text" placeholder='Enter %' value={prixDetailsLocal?.infantPassBackCommission} onChange={(e) => {commonOnChange('infantPassBackCommission',e?.target?.value)}} disabled={disableEdit}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label">MIN SELL</label>
                                                            <input className="form-control cm-input" type="text" placeholder='Enter $' value={prixDetailsLocal?.minumumSellingPrice} onChange={(e) => {commonOnChange('minumumSellingPrice',e?.target?.value)}} disabled={disableEdit}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card Tariff__BorderB">
                                    <div className="card-body py-0">
                                        <div className='row'>
                                            <div className='col-md-6 prix_borderRight py-6'>
                                                <div className=" cm-head-2 mb-4 mr-8">
                                                    <div className="d-flex justify-content-between">
                                                        <span className="">Navcan (Surcharges)</span>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className="row">
                                                            <div className="col-md-4 prix_iconBox">
                                                                <img src={icon_adult} />
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="cm-col">
                                                                    <label className="cm-head-label">OUR COMMISSION</label>
                                                                    <input className="form-control cm-input" type="text" placeholder='Enter %' value={prixDetailsLocal?.adultCommissionOnYQNav} onChange={(e) => {commonOnChange('adultCommissionOnYQNav',e?.target?.value)}} disabled={disableEdit}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className="row">
                                                            <div className="col-md-10">
                                                                <div className="cm-col">
                                                                    <label className="cm-head-label">AGENCY COMMISSION</label>
                                                                    <input className="form-control cm-input cm__inputW80" type="text" placeholder='Enter %' value={prixDetailsLocal?.adultPassBackCommissionOnYQNav} onChange={(e) => {commonOnChange('adultPassBackCommissionOnYQNav',e?.target?.value)}} disabled={disableEdit}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-md-6 py-6'>
                                                <div className=" cm-head-2 mb-4">
                                                    <div className="d-flex justify-content-between">
                                                        <span className="">Carburant</span>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className="row">
                                                            <div className="col-md-4 prix_iconBox">
                                                                <img src={icon_adult} />
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="cm-col">
                                                                    <label className="cm-head-label">OUR COMMISSION</label>
                                                                    <input className="form-control cm-input" type="text" placeholder='Enter %' value={prixDetailsLocal?.adultCommissionOnYQ} onChange={(e) => {commonOnChange('adultCommissionOnYQ',e?.target?.value)}} disabled={disableEdit}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className="row">
                                                            <div className="col-md-10">
                                                                <div className="cm-col">
                                                                    <label className="cm-head-label">AGENCY COMMISSION</label>
                                                                    <input className="form-control cm-input cm__inputW80" type="text" placeholder='Enter %' value={prixDetailsLocal?.adultPassBackCommissionOnYQ} onChange={(e) => {commonOnChange('adultPassBackCommissionOnYQ',e?.target?.value)}} disabled={disableEdit}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card Tariff__BorderB">
                                    <div className="card-body py-0">
                                        <div className='row'>
                                            <div className='col-md-6 prix_borderRight py-6'>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className="row">
                                                            <div className="col-md-4 prix_iconBox">
                                                                <img src={icon_child} />
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="cm-col">
                                                                    <label className="cm-head-label">OUR COMMISSION</label>
                                                                    <input className="form-control cm-input" type="text" placeholder='Enter %' value={prixDetailsLocal?.childCommissionOnYQNav} onChange={(e) => {commonOnChange('childCommissionOnYQNav',e?.target?.value)}} disabled={disableEdit}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className="row">
                                                            <div className="col-md-10">
                                                                <div className="cm-col">
                                                                    <label className="cm-head-label">AGENCY COMMISSION</label>
                                                                    <input className="form-control cm-input cm__inputW80" type="text" placeholder='Enter %' value={prixDetailsLocal?.childPassBackCommissionOnYQNav} onChange={(e) => {commonOnChange('childPassBackCommissionOnYQNav',e?.target?.value)}} disabled={disableEdit}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-md-6 py-6'>

                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className="row">
                                                            <div className="col-md-4 prix_iconBox">
                                                                <img src={icon_child} />
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="cm-col">
                                                                    <label className="cm-head-label">OUR COMMISSION</label>
                                                                    <input className="form-control cm-input" type="text" placeholder='Enter %' value={prixDetailsLocal?.childCommissionOnYQ} onChange={(e) => {commonOnChange('childCommissionOnYQ',e?.target?.value)}} disabled={disableEdit}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className="row">
                                                            <div className="col-md-10">
                                                                <div className="cm-col">
                                                                    <label className="cm-head-label">AGENCY COMMISSION</label>
                                                                    <input className="form-control cm-input cm__inputW80" type="text" placeholder='Enter %' value={prixDetailsLocal?.childPassBackCommissionOnYQ} onChange={(e) => {commonOnChange('childPassBackCommissionOnYQ',e?.target?.value)}} disabled={disableEdit}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card Tariff__BorderB">
                                    <div className="card-body py-0">
                                        <div className='row'>
                                            <div className='col-md-6 prix_borderRight py-6'>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className="row">
                                                            <div className="col-md-4 prix_iconBox">
                                                                <img src={icon_childfriendly} />
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="cm-col">
                                                                    <label className="cm-head-label">OUR COMMISSION</label>
                                                                    <input className="form-control cm-input" type="text" placeholder='Enter %' value={prixDetailsLocal?.enfantCommissionOnYQNav} onChange={(e) => {commonOnChange('enfantCommissionOnYQNav',e?.target?.value)}} disabled={disableEdit}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className="row">
                                                            <div className="col-md-10">
                                                                <div className="cm-col">
                                                                    <label className="cm-head-label">AGENCY COMMISSION</label>
                                                                    <input className="form-control cm-input cm__inputW80" type="text" placeholder='Enter %' value={prixDetailsLocal?.enfantPassBackCommissionOnYQNav} onChange={(e) => {commonOnChange('enfantPassBackCommissionOnYQNav',e?.target?.value)}} disabled={disableEdit}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-md-6 py-6'>

                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className="row">
                                                            <div className="col-md-4 prix_iconBox">
                                                                <img src={icon_childfriendly} />
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="cm-col">
                                                                    <label className="cm-head-label">OUR COMMISSION</label>
                                                                    <input className="form-control cm-input" type="text" placeholder='Enter %' value={prixDetailsLocal?.enfantCommissionOnYQ} onChange={(e) => {commonOnChange('enfantCommissionOnYQ',e?.target?.value)}} disabled={disableEdit}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className="row">
                                                            <div className="col-md-10">
                                                                <div className="cm-col">
                                                                    <label className="cm-head-label">AGENCY COMMISSION</label>
                                                                    <input className="form-control cm-input cm__inputW80" type="text" placeholder='Enter %' value={prixDetailsLocal?.enfantPassBackCommissionOnYQ} onChange={(e) => {commonOnChange('enfantPassBackCommissionOnYQ',e?.target?.value)}} disabled={disableEdit}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>








                            </TabPanel>

                            <TabPanel value="2" className='m-0 p-0'>

                                <div className="card Tariff__BorderB">
                                    <div className="card-body">
                                        <div className="cm-head-2 mb-4">
                                            <div className="d-flex justify-content-between">
                                                <span>Blackout Dates</span>
                                            </div>
                                        </div>
                                        {prixDetailsLocal?.blackOutDates?.length ? prixDetailsLocal?.blackOutDates?.map((blackOutDateValue, index)=>{return(<>
                                            <div key={index} className="row">
                                            <div className="col-md-3 cm-col">
                                                <label className="cm-head-label">Start Date </label>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            className='BulkUpdate__DatePicker'
                                                            format="dd MMM yyyy"
                                                            value={blackOutDateValue.fromDate ? new Date(blackOutDateValue.fromDate) : null}
                                                            onChange={(date) => {
                                                                if (date) {
                                                                    const fromDate = new Date(date).toISOString().split('.')[0] + '.000Z';
                                                                    const updatedBlackOutDates = prixDetailsLocal?.blackOutDates.map((item, idx) =>
                                                                        idx === index ? { ...item, fromDate: fromDate } : item
                                                                    );
                                                                    commonOnChange('blackOutDates', updatedBlackOutDates);
                                                                }
                                                            }}
                                                            disabled={disableEdit}
                                                        />

                                                </MuiPickersUtilsProvider>
                                            </div>
                                            <div className="col-md-3 cm-col">
                                                <label className="cm-head-label">End Date </label>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    {/* <KeyboardDatePicker className='BulkUpdate__DatePicker' format="dd MMM yyyy" value={blackOutDateValue.toDate} onChange={() => { }} disabled={disableEdit}/> */}
                                                    <KeyboardDatePicker
                                                            className='BulkUpdate__DatePicker'
                                                            format="dd MMM yyyy"
                                                            value={blackOutDateValue.toDate ? new Date(blackOutDateValue.toDate) : null}
                                                            onChange={(date) => {
                                                                if (date) {
                                                                    const toDate = new Date(date).toISOString().split('.')[0] + '.000Z';
                                                                    const updatedBlackOutDates = prixDetailsLocal?.blackOutDates.map((item, idx) =>
                                                                        idx === index ? { ...item, toDate: toDate } : item
                                                                    );
                                                                    commonOnChange('blackOutDates', updatedBlackOutDates);
                                                                }
                                                            }}
                                                            disabled={disableEdit}
                                                        />
                                                </MuiPickersUtilsProvider>
                                            </div>

                                            <div className="col-md-3">
                                                <label className="cm-head-label"> </label>

                                                <div className="row">
                                                    <div className="col-md-6 ">
                                                        <FormControlLabel control={<Checkbox color="primary" checked={Boolean(blackOutDateValue.aller)} disabled={disableEdit} 
                                                        onChange={(e) => {
                                                            if (e) {
                                                                const aller = e?.target?.checked;
                                                                const updatedBlackOutDates = prixDetailsLocal?.blackOutDates.map((item, idx) =>
                                                                    idx === index ? { ...item, aller: aller } : item
                                                                );
                                                                commonOnChange('blackOutDates', updatedBlackOutDates);
                                                            }
                                                        }}
                                                        />} label="Departure" />
                                                    </div>
                                                    <div className="col-md-6 ">
                                                        <FormControlLabel control={<Checkbox color="primary" checked={Boolean(blackOutDateValue.retour)} disabled={disableEdit}
                                                        onChange={(e) => {
                                                            if (e) {
                                                                const retour = e?.target?.checked;
                                                                const updatedBlackOutDates = prixDetailsLocal?.blackOutDates.map((item, idx) =>
                                                                    idx === index ? { ...item, retour: retour } : item
                                                                );
                                                                commonOnChange('blackOutDates', updatedBlackOutDates);
                                                            }
                                                        }}
                                                        />} label="Return" />
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-md-3 cm-col">
                                                <label className="cm-head-label">Exception Type</label>
                                                <div className="row">
                                                    <div className="col-md-7">
                                                        <Select className='BulkUpdate__DatePicker' value={blackOutDateValue.exceptionType} disabled={disableEdit}
                                                        onChange={(e) => {
                                                            if (e) {
                                                                const exceptionType = e?.target?.value;
                                                                const updatedBlackOutDates = prixDetailsLocal?.blackOutDates.map((item, idx) =>
                                                                    idx === index ? { ...item, exceptionType: exceptionType } : item
                                                                );
                                                                commonOnChange('blackOutDates', updatedBlackOutDates);
                                                            }
                                                        }}
                                                        >
                                                                <MenuItem value={'BlackOut'}>BlackOut</MenuItem>
                                                                <MenuItem value={'Reduction'}>Reduction</MenuItem>
                                                                <MenuItem value={'Supplement'}>Supplement</MenuItem>
                                                        </Select>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className='setD-flex'>
                                                                <Button
                                                                    className="BulkUpdate__removeBtn"
                                                                    disabled={disableEdit || prixDetailsLocal?.blackOutDates?.length === 0}
                                                                    onClick={() => {
                                                                        if (prixDetailsLocal?.blackOutDates?.length > 0) {
                                                                            // Create a new array excluding the item at the current index
                                                                            const updatedBlackOutDates = prixDetailsLocal.blackOutDates.filter((_, i) => i !== index);
                                                                            commonOnChange('blackOutDates', updatedBlackOutDates);
                                                                        }
                                                                    }}
                                                                >
                                                                    <i className="fas fa-trash-alt"></i>
                                                                </Button>


                                                                <Button
                                                                    className='BulkUpdate__AddBtn'
                                                                    disabled={disableEdit}
                                                                    onClick={() => {
                                                                        if (prixDetailsLocal?.blackOutDates?.length > 0) {
                                                                            const updatedBlackOutDates = [...prixDetailsLocal.blackOutDates, blackOutDates];
                                                                            commonOnChange('blackOutDates', updatedBlackOutDates);
                                                                        }
                                                                    }}
                                                                >
                                                                    <i className="fas fa-plus-circle"></i>
                                                                </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div></>
                                        )}) : <>
                                        <div className="row">
                                            <div className="col-md-3 cm-col">
                                                <label className="cm-head-label">Start Date </label>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <KeyboardDatePicker
                                                                className='BulkUpdate__DatePicker'
                                                                format="dd MMM yyyy"
                                                                value={null}
                                                                onChange={(date) => {
                                                                    if (date) {
                                                                        const fromDate = new Date(date).toISOString().split('.')[0] + '.000Z';
                                                                        const updatedBlackOutDates = [{ ...blackOutDates, fromDate: fromDate }];
                                                                        commonOnChange('blackOutDates', updatedBlackOutDates);
                                                                    }
                                                                }}
                                                                disabled={disableEdit}
                                                            />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                            <div className="col-md-3 cm-col">
                                                <label className="cm-head-label">End Date </label>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker className='BulkUpdate__DatePicker' format="dd MMM yyyy" disabled={disableEdit} value={null} 
                                                    onChange={(date) => {
                                                        if (date) {
                                                            const toDate = new Date(date).toISOString().split('.')[0] + '.000Z';
                                                            const updatedBlackOutDates = [{ ...blackOutDates, toDate: toDate }];
                                                            commonOnChange('blackOutDates', updatedBlackOutDates);
                                                        }
                                                    }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>

                                            <div className="col-md-3">
                                                <label className="cm-head-label"> </label>

                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <FormControlLabel control={<Checkbox color="primary" disabled={disableEdit}
                                                        onChange={(e) => {
                                                            if (e) {
                                                                const aller = e?.target?.checked;
                                                                const updatedBlackOutDates = [{ ...blackOutDates, aller: aller }];
                                                                commonOnChange('blackOutDates', updatedBlackOutDates);
                                                            }
                                                        }}
                                                        />} label="Departure" />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <FormControlLabel control={<Checkbox color="primary" disabled={disableEdit}
                                                        onChange={(e) => {
                                                            if (e) {
                                                                const retour = e?.target?.checked;
                                                                const updatedBlackOutDates = [{ ...blackOutDates, retour: retour }];
                                                                commonOnChange('blackOutDates', updatedBlackOutDates);
                                                            }
                                                        }}
                                                        />} label="Return" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3 cm-col">
                                                <label className="cm-head-label">Exception Type</label>
                                                <div className="row">
                                                    <div className="col-md-7">
                                                        <Select className='BulkUpdate__DatePicker' disabled={disableEdit}
                                                        onChange={(e) => {
                                                            if (e) {
                                                                const exceptionType = e?.target?.value;
                                                                const updatedBlackOutDates = [{ ...blackOutDates, exceptionType: exceptionType }];
                                                                commonOnChange('blackOutDates', updatedBlackOutDates);
                                                            }
                                                        }}
                                                        >
                                                            <MenuItem value={10}>Ten</MenuItem>
                                                            <MenuItem value={20}>Twenty</MenuItem>
                                                            <MenuItem value={30}>Thirty</MenuItem>
                                                        </Select>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className='setD-flex'>
                                                            <Button className="BulkUpdate__removeBtn" disabled={true} ><i className="fas fa-trash-alt"></i></Button>
                                                            <Button className='BulkUpdate__AddBtn' disabled={disableEdit} onClick={()=>{if(prixDetailsLocal?.blackOutDates?.length == 0){commonOnChange('blackOutDates',[blackOutDates,blackOutDates])}}}><i className="fas fa-plus-circle"></i></Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div></>
                                        }
                                    </div>
                                </div>


                                <div className="card Tariff__BorderB">
                                    <div className="card-body">
                                        <div className="cm-head-2 mb-4">
                                            <div className="d-flex justify-content-between">
                                                <span>Blackout Days</span>
                                            </div>
                                        </div>
                                        {prixDetailsLocal?.blackOutDays?.length ?  prixDetailsLocal?.blackOutDays?.map((blackOutDayValue, index)=>{return(<>
                                            <div className="row">
                                            <div className="col-md-3 cm-col">
                                                <label className="cm-head-label">Days </label>
                                                <input className="form-control cm-input" value={blackOutDayValue?.day} disabled={disableEdit}
                                                onChange={(e) => {
                                                    if (e) {
                                                        const day = e?.target?.value;
                                                        const updatedBlackOutDays = prixDetailsLocal?.blackOutDays.map((item, idx) =>
                                                            idx === index ? { ...item, day: day } : item
                                                        );
                                                        commonOnChange('blackOutDays', updatedBlackOutDays);
                                                    }
                                                }}
                                                />
                                            </div>


                                            <div className="col-md-3">
                                                <label className="cm-head-label"> </label>

                                                <div className="row">
                                                    <div className="col-md-6 ">
                                                        <FormControlLabel control={<Checkbox color="primary" checked={Boolean(blackOutDayValue?.aller)} disabled={disableEdit}
                                                        onChange={(e) => {
                                                            if (e) {
                                                                const aller = e?.target?.checked;
                                                                const updatedBlackOutDays = prixDetailsLocal?.blackOutDays.map((item, idx) =>
                                                                    idx === index ? { ...item, aller: aller } : item
                                                                );
                                                                commonOnChange('blackOutDays', updatedBlackOutDays);
                                                            }
                                                        }}
                                                        />} label="Departure" />
                                                    </div>
                                                    <div className="col-md-6 ">
                                                        <FormControlLabel control={<Checkbox color="primary" checked={Boolean(blackOutDayValue?.retour)} disabled={disableEdit}
                                                        onChange={(e) => {
                                                            if (e) {
                                                                const retour = e?.target?.checked;
                                                                const updatedBlackOutDays = prixDetailsLocal?.blackOutDays.map((item, idx) =>
                                                                    idx === index ? { ...item, retour: retour } : item
                                                                );
                                                                commonOnChange('blackOutDays', updatedBlackOutDays);
                                                            }
                                                        }}
                                                        />} label="Return" />
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="col-md-3 cm-col">
                                                <label className="cm-head-label">Exception Type</label>
                                                <div className="row">
                                                    <div className="col-md-7">
                                                        <Select className='BulkUpdate__DatePicker' value={blackOutDayValue?.exceptionType} disabled={disableEdit}
                                                        onChange={(e) => {
                                                            if (e) {
                                                                const exceptionType = e?.target?.value;
                                                                const updatedBlackOutDays = prixDetailsLocal?.blackOutDays.map((item, idx) =>
                                                                    idx === index ? { ...item, exceptionType: exceptionType } : item
                                                                );
                                                                commonOnChange('blackOutDays', updatedBlackOutDays);
                                                            }
                                                        }}
                                                        >
                                                                <MenuItem value={'BlackOut'}>BlackOut</MenuItem>
                                                                <MenuItem value={'Reduction'}>Reduction</MenuItem>
                                                                <MenuItem value={'Supplement'}>Supplement</MenuItem>
                                                        </Select>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-md-3 cm-col">
                                                <label className="cm-head-label"> </label>
                                                <div className="row">
                                                    <div className="col-md-7">

                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className='setD-flex'>
                                                                <Button
                                                                    className="BulkUpdate__removeBtn"
                                                                    disabled={disableEdit || prixDetailsLocal?.blackOutDays?.length === 0}
                                                                    onClick={() => {
                                                                        if (prixDetailsLocal?.blackOutDays?.length > 0) {
                                                                            // Create a new array excluding the item at the current index
                                                                            const updatedBlackOutDays = prixDetailsLocal.blackOutDays.filter((_, i) => i !== index);
                                                                            commonOnChange('blackOutDays', updatedBlackOutDays);
                                                                        }
                                                                    }}
                                                                >
                                                                    <i className="fas fa-trash-alt"></i>
                                                                </Button>

                                                                <Button
                                                                    className='BulkUpdate__AddBtn'
                                                                    disabled={disableEdit}
                                                                    onClick={() => {
                                                                        if (prixDetailsLocal?.blackOutDays?.length > 0) {
                                                                            const updatedBlackOutDays = [...prixDetailsLocal.blackOutDays, blackOutDays];
                                                                            commonOnChange('blackOutDays', updatedBlackOutDays);
                                                                        }
                                                                    }}
                                                                >
                                                                    <i className="fas fa-plus-circle"></i>
                                                                </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </>)}) : <>
                                        <div className="row">
                                            <div className="col-md-3 cm-col">
                                                <label className="cm-head-label">Days </label>
                                                <input className="form-control cm-input" disabled={disableEdit}
                                                onChange={(e) => {
                                                    if (e) {
                                                        const day = e?.target?.value;
                                                        const updatedBlackOutDays = [{ ...blackOutDays, day: day }];
                                                        commonOnChange('blackOutDays', updatedBlackOutDays);
                                                    }
                                                }}
                                                />
                                            </div>


                                            <div className="col-md-3">
                                                <label className="cm-head-label"> </label>

                                                <div className="row">
                                                    <div className="col-md-6 ">
                                                        <FormControlLabel control={<Checkbox color="primary" disabled={disableEdit}/>} label="Departure" 
                                                        onChange={(e) => {
                                                            if (e) {
                                                                const aller = e?.target?.checked;
                                                                const updatedBlackOutDays = [{ ...blackOutDays, aller: aller }];
                                                                commonOnChange('blackOutDays', updatedBlackOutDays);
                                                            }
                                                        }}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 ">
                                                        <FormControlLabel control={<Checkbox color="primary" disabled={disableEdit}/>} label="Return" 
                                                        onChange={(e) => {
                                                            if (e) {
                                                                const retour = e?.target?.checked;
                                                                const updatedBlackOutDays = [{ ...blackOutDays, retour: retour }];
                                                                commonOnChange('blackOutDays', updatedBlackOutDays);
                                                            }
                                                        }}
                                                        />
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="col-md-3 cm-col">
                                                <label className="cm-head-label">Exception Type</label>
                                                <div className="row">
                                                    <div className="col-md-7">
                                                        <Select className='BulkUpdate__DatePicker'disabled={disableEdit}
                                                        onChange={(e) => {
                                                            if (e) {
                                                                const exceptionType = e?.target?.value;
                                                                const updatedBlackOutDays = [{ ...blackOutDays, exceptionType: exceptionType }];
                                                                commonOnChange('blackOutDays', updatedBlackOutDays);
                                                            }
                                                        }}
                                                        >
                                                                    <MenuItem value={'BlackOut'}>BlackOut</MenuItem>
                                                                    <MenuItem value={'Reduction'}>Reduction</MenuItem>
                                                                    <MenuItem value={'Supplement'}>Supplement</MenuItem>
                                                        </Select>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-md-3 cm-col">
                                                <label className="cm-head-label"> </label>
                                                <div className="row">
                                                    <div className="col-md-7">

                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className='setD-flex'>
                                                            <Button className="BulkUpdate__removeBtn " disabled={true}><i className="fas fa-trash-alt"></i></Button>
                                                            <Button className='BulkUpdate__AddBtn' disabled={disableEdit} onClick={()=>{if(prixDetailsLocal?.blackOutDates?.length == 0){commonOnChange('blackOutDays',[blackOutDays,blackOutDays])}}}><i className="fas fa-plus-circle"></i></Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </>
                                            }
                                    </div>
                                </div>

                                <div className="card Tariff__BorderB">
                                    <div className="card-body">
                                        <div className="cm-head-2 mb-4">
                                            <div className="d-flex justify-content-between">
                                                <span>Blackout Airlines</span>
                                            </div>
                                        </div>
                                        {prixDetailsLocal?.blackOutCarrier?.length ?  prixDetailsLocal?.blackOutCarrier?.map((blackOutAirlineValue, index)=>{return(<>
                                            <div className="row">
                                            <div className="col-md-3 cm-col">
                                                <label className="cm-head-label">Carrier Code </label>
                                                <input className="form-control cm-input" value={blackOutAirlineValue?.carrierCode} disabled={disableEdit}
                                                onChange={(e) => {
                                                    if (e) {
                                                        const carrierCode = e?.target?.value;
                                                        const updatedBlackOutCarrier = prixDetailsLocal?.blackOutCarrier.map((item, idx) =>
                                                            idx === index ? { ...item, carrierCode: carrierCode } : item
                                                        );
                                                        commonOnChange('blackOutCarrier', updatedBlackOutCarrier);
                                                    }
                                                }}
                                                />
                                            </div>
                                            <div className="col-md-3 cm-col">
                                                <div className='row'>
                                                    <div className="col-md-6 cm-col">
                                                        <label className="cm-head-label">From </label>
                                                        <input className="form-control cm-input" value={blackOutAirlineValue?.flightNumberFrom} disabled={disableEdit}
                                                        onChange={(e) => {
                                                            if (e) {
                                                                const flightNumberFrom = e?.target?.value;
                                                                const updatedBlackOutCarrier = prixDetailsLocal?.blackOutCarrier.map((item, idx) =>
                                                                    idx === index ? { ...item, flightNumberFrom: flightNumberFrom } : item
                                                                );
                                                                commonOnChange('blackOutCarrier', updatedBlackOutCarrier);
                                                            }
                                                        }}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 cm-col">
                                                <label className="cm-head-label">To</label>
                                                <input className="form-control cm-input" value={blackOutAirlineValue?.flightNumberTo} disabled={disableEdit}
                                                onChange={(e) => {
                                                    if (e) {
                                                        const flightNumberTo = e?.target?.value;
                                                        const updatedBlackOutCarrier = prixDetailsLocal?.blackOutCarrier.map((item, idx) =>
                                                            idx === index ? {   item, flightNumberTo: flightNumberTo } : item
                                                        );
                                                        commonOnChange('blackOutCarrier', updatedBlackOutCarrier);
                                                    }
                                                }}
                                                />
                                            </div>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <label className="cm-head-label"> </label>

                                                <div className="row">
                                                    <div className="col-md-6 ">
                                                        <FormControlLabel control={<Checkbox color="primary" checked={blackOutAirlineValue?.aller} disabled={disableEdit}/>} label="Departure" 
                                                        onChange={(e) => {
                                                            if (e) {
                                                                const aller = e?.target?.checked;
                                                                const updatedBlackOutCarrier = prixDetailsLocal?.blackOutCarrier.map((item, idx) =>
                                                                    idx === index ? { ...item, aller: aller } : item
                                                                );
                                                                commonOnChange('blackOutCarrier', updatedBlackOutCarrier);
                                                            }
                                                        }}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 ">
                                                        <FormControlLabel control={<Checkbox color="primary" checked={blackOutAirlineValue?.retour} disabled={disableEdit}/>} label="Return" 
                                                        onChange={(e) => {
                                                            if (e) {
                                                                const retour = e?.target?.checked;
                                                                const updatedBlackOutCarrier = prixDetailsLocal?.blackOutCarrier.map((item, idx) =>
                                                                    idx === index ? { ...item, retour: retour } : item
                                                                );
                                                                commonOnChange('blackOutCarrier', updatedBlackOutCarrier);
                                                            }
                                                        }}
                                                        />
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="col-md-3 cm-col">
                                                <label className="cm-head-label">Exception Type</label>
                                                <div className="row">
                                                    <div className="col-md-7">
                                                        <Select className='BulkUpdate__DatePicker' value={blackOutAirlineValue?.exceptionType} disabled={disableEdit}
                                                        onChange={(e) => {
                                                            if (e) {
                                                                const exceptionType = e?.target?.value;
                                                                const updatedBlackOutCarrier = prixDetailsLocal?.blackOutCarrier.map((item, idx) =>
                                                                    idx === index ? { ...item, exceptionType: exceptionType } : item
                                                                );
                                                                commonOnChange('blackOutCarrier', updatedBlackOutCarrier);
                                                            }
                                                        }}
                                                        >
                                                                <MenuItem value={'BlackOut'}>BlackOut</MenuItem>
                                                                <MenuItem value={'Reduction'}>Reduction</MenuItem>
                                                                <MenuItem value={'Supplement'}>Supplement</MenuItem>
                                                        </Select>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className='setD-flex'>
                                                                <Button
                                                                    className="BulkUpdate__removeBtn"
                                                                    disabled={disableEdit || prixDetailsLocal?.blackOutCarrier?.length == 0}
                                                                    onClick={() => {
                                                                        if (prixDetailsLocal?.blackOutCarrier?.length > 0) {
                                                                            const updatedBlackOutCarrier = prixDetailsLocal.blackOutCarrier.filter((_, i) => i !== index);
                                                                            commonOnChange('blackOutCarrier', updatedBlackOutCarrier);
                                                                        }
                                                                    }}
                                                                >
                                                                    <i className="fas fa-trash-alt"></i>
                                                                </Button>

                                                                <Button
                                                                    className='BulkUpdate__AddBtn'
                                                                    disabled={disableEdit}
                                                                    onClick={() => {
                                                                        if (prixDetailsLocal?.blackOutCarrier?.length > 0) {
                                                                            const updatedBlackOutCarrier = [...prixDetailsLocal.blackOutCarrier, blackOutCarrier];
                                                                            commonOnChange('blackOutCarrier', updatedBlackOutCarrier);
                                                                        }
                                                                    }}
                                                                >
                                                                    <i className="fas fa-plus-circle"></i>
                                                                </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div></>)}):
                                                <>
                                                <div className="row">
                                                <div className="col-md-3 cm-col">
                                                    <label className="cm-head-label">Carrier Code </label>
                                                    <input className="form-control cm-input"  disabled={disableEdit}
                                                    onChange={(e) => {
                                                        if (e) {
                                                            const carrierCode = e?.target?.value;
                                                            const updatedBlackOutCarrier = [{ ...blackOutCarrier, carrierCode: carrierCode }];
                                                            commonOnChange('blackOutCarrier', updatedBlackOutCarrier);
                                                        }
                                                    }}
                                                    />
                                                </div>
                                                <div className="col-md-3 cm-col">
                                                    <div className='row'>
                                                        <div className="col-md-6 cm-col">
                                                            <label className="cm-head-label">From </label>
                                                            <input className="form-control cm-input" disabled={disableEdit}
                                                            onChange={(e) => {
                                                                if (e) {
                                                                    const flightNumberFrom = e?.target?.value;
                                                                    const updatedBlackOutCarrier = [{ ...blackOutCarrier, flightNumberFrom: flightNumberFrom }];
                                                                    commonOnChange('blackOutCarrier', updatedBlackOutCarrier);
                                                                }
                                                            }}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 cm-col">
                                                    <label className="cm-head-label">To</label>
                                                    <input className="form-control cm-input" disabled={disableEdit}
                                                    onChange={(e) => {
                                                        if (e) {
                                                            const flightNumberTo = e?.target?.value;
                                                            const updatedBlackOutCarrier = [{ ...blackOutCarrier, flightNumberTo: flightNumberTo }];
                                                            commonOnChange('blackOutCarrier', updatedBlackOutCarrier);
                                                        }
                                                    }}
                                                    />
                                                </div>
                                                    </div>
                                                </div>
    
                                                <div className="col-md-3">
                                                    <label className="cm-head-label"> </label>
    
                                                    <div className="row">
                                                        <div className="col-md-6 ">
                                                            <FormControlLabel control={<Checkbox color="primary" disabled={disableEdit}/>} label="Departure" 
                                                            onChange={(e) => {
                                                                if (e) {
                                                                    const aller = e?.target?.checked;
                                                                    const updatedBlackOutCarrier = [{ ...blackOutCarrier, aller: aller }];
                                                                    commonOnChange('blackOutCarrier', updatedBlackOutCarrier);
                                                                }
                                                            }}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 ">
                                                            <FormControlLabel control={<Checkbox color="primary" disabled={disableEdit}/>} label="Return" 
                                                            onChange={(e) => {
                                                                if (e) {
                                                                    const retour = e?.target?.checked;
                                                                    const updatedBlackOutCarrier = [{ ...blackOutCarrier, retour: retour }];
                                                                    commonOnChange('blackOutCarrier', updatedBlackOutCarrier);
                                                                }
                                                            }}
                                                            />
                                                        </div>
    
                                                    </div>
                                                </div>
    
                                                <div className="col-md-3 cm-col">
                                                    <label className="cm-head-label">Exception Type</label>
                                                    <div className="row">
                                                        <div className="col-md-7">
                                                            <Select className='BulkUpdate__DatePicker' disabled={disableEdit}
                                                            onChange={(e) => {
                                                                if (e) {
                                                                    const exceptionType = e?.target?.value;
                                                                    const updatedBlackOutCarrier = [{ ...blackOutCarrier, exceptionType: exceptionType }];
                                                                    commonOnChange('blackOutCarrier', updatedBlackOutCarrier);
                                                                }
                                                            }}
                                                            >
                                                                    <MenuItem value={'BlackOut'}>BlackOut</MenuItem>
                                                                    <MenuItem value={'Reduction'}>Reduction</MenuItem>
                                                                    <MenuItem value={'Supplement'}>Supplement</MenuItem>
                                                            </Select>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className='setD-flex'>
                                                                <Button className="BulkUpdate__removeBtn " disabled={true}><i className="fas fa-trash-alt"></i></Button>
                                                                <Button className='BulkUpdate__AddBtn' disabled={disableEdit} onClick={()=>{if(prixDetailsLocal?.blackOutCarrier?.length == 0){commonOnChange('blackOutCarrier',[blackOutCarrier,blackOutCarrier])}}}><i className="fas fa-plus-circle"></i></Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div></>
                                        }
                                    </div>
                                </div>

                            </TabPanel>

                            <TabPanel value="3" className='m-0 p-0'>
                                <div className="card Tariff__BorderB">
                                    <div className="card-body">
                                        <div className="cm-head-2 mb-4">
                                            <div className="d-flex justify-content-between">
                                                <span>Endorsement</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">VIT</label>
                                                    <input className="form-control cm-input" type="text" value={prixDetailsLocal?.endorsement?.VIT} onChange={(e) => {commonOnChange('endorsement',{...prixDetailsLocal?.endorsement,['VIT']:e?.target?.value})}} disabled={disableEdit}/>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">SKY </label>
                                                    <input className="form-control cm-input" type="text" value={prixDetailsLocal?.endorsement?.SKY} onChange={(e) => {commonOnChange('endorsement',{...prixDetailsLocal?.endorsement,['SKY']:e?.target?.value})}} disabled={disableEdit}/>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">DFW</label>
                                                    <input className="form-control cm-input" type="text" value={prixDetailsLocal?.endorsement?.DFW} onChange={(e) => {commonOnChange('endorsement',{...prixDetailsLocal?.endorsement,['DFW']:e?.target?.value})}} disabled={disableEdit}/>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">FUN</label>
                                                    <input className="form-control cm-input" type="text" value={prixDetailsLocal?.endorsement?.FUN} onChange={(e) => {commonOnChange('endorsement',{...prixDetailsLocal?.endorsement,['FUN']:e?.target?.value})}} disabled={disableEdit}/>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">TC</label>
                                                    <input className="form-control cm-input" type="text" value={prixDetailsLocal?.endorsement?.TC} onChange={(e) => {commonOnChange('endorsement',{...prixDetailsLocal?.endorsement,['TC']:e?.target?.value})}} disabled={disableEdit}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card Tariff__BorderB">
                                    <div className="card-body">
                                        <div className="cm-head-2 mb-4">
                                            <div className="d-flex justify-content-between">
                                                <span>Tour Code</span>
                                            </div>
                                        </div>
                                        {prixDetailsLocal?.tourCode?.length ? prixDetailsLocal?.tourCode?.map((tour, index)=>{return(<>
                                            <div className="row">
                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label">Office</label>
                                                                <input
                                                                    className="form-control cm-input"
                                                                    type="text"
                                                                    value={tour?.marketCode || ''}
                                                                    onChange={(e) => {
                                                                        const newMarketCode = e.target.value;
                                                                        const updatedTourCodes = prixDetailsLocal?.tourCode.map((item, idx) =>
                                                                            idx === index ? { ...item, marketCode: newMarketCode } : item
                                                                        );
                                                                        commonOnChange('tourCode', updatedTourCodes);
                                                                    }}
                                                                    disabled={disableEdit}
                                                                />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label">Adult</label>
                                                            <input className="form-control cm-input" type="text" value={tour?.passengerType?.includes("ADULT") ? tour?.tourCodes : null} disabled={disableEdit}
                                                            onChange={(e) => {
                                                                const tourCodes = e.target.value;
                                                                const updatedTourCodes = prixDetailsLocal?.tourCode.map((item, idx) =>
                                                                    idx === index ? { ...item, tourCodes: tourCodes, passengerType: tour?.passengerType?.includes("ADULT") ?  tour?.passengerType : tour?.passengerType?.length ? `${tour?.passengerType},ADULT` : `${tour?.passengerType}ADULT`} : item
                                                                );
                                                                commonOnChange('tourCode', updatedTourCodes);
                                                            }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label">Child</label>
                                                            <input className="form-control cm-input" type="text" value={tour?.passengerType?.includes("CHILD") ? tour?.tourCodes : null} disabled={disableEdit} 
                                                            onChange={(e) => {
                                                                const tourCodes = e.target.value;
                                                                const updatedTourCodes = prixDetailsLocal?.tourCode.map((item, idx) =>
                                                                    idx === index ? { ...item, tourCodes: tourCodes, passengerType: tour?.passengerType?.includes("CHILD") ?  tour?.passengerType : tour?.passengerType?.length ? `${tour?.passengerType},CHILD`: `${tour?.passengerType}CHILD`} : item
                                                                );
                                                                commonOnChange('tourCode', updatedTourCodes);
                                                            }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label">Infant</label>
                                                            <input className="form-control cm-input" type="text" value={tour?.passengerType?.includes("INFANT") ? tour?.tourCodes : null} disabled={disableEdit}
                                                            onChange={(e) => {
                                                                const tourCodes = e.target.value;
                                                                const updatedTourCodes = prixDetailsLocal?.tourCode.map((item, idx) =>
                                                                    idx === index ? { ...item, tourCodes: tourCodes, passengerType:  tour?.passengerType?.includes("INFANT") ?  tour?.passengerType : tour?.passengerType?.length ? `${tour?.passengerType},INFANT` : `${tour?.passengerType}INFANT`} : item
                                                                );
                                                                commonOnChange('tourCode', updatedTourCodes);
                                                            }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                    <label className="cm-head-label"></label>
                                                            <div className='setD-flex'>
                                                                <Button
                                                                    className="BulkUpdate__removeBtn"
                                                                    disabled={disableEdit || prixDetailsLocal?.tourCode?.length == 0}
                                                                    onClick={() => {
                                                                        if (prixDetailsLocal?.tourCode?.length > 0) {
                                                                            const updatedTourCode = prixDetailsLocal.tourCode.filter((_, i) => i !== index);
                                                                            commonOnChange('tourCode', updatedTourCode);
                                                                        }
                                                                    }}
                                                                >
                                                                    <i className="fas fa-trash-alt"></i>
                                                                </Button>

                                                                <Button
                                                                    className='BulkUpdate__AddBtn'
                                                                    disabled={disableEdit}
                                                                    onClick={() => {
                                                                        if (prixDetailsLocal?.tourCode?.length > 0) {
                                                                            const updatedTourCode = [...prixDetailsLocal.tourCode, tourCode];
                                                                            commonOnChange('tourCode', updatedTourCode);
                                                                        }
                                                                    }}
                                                                >
                                                                    <i className="fas fa-plus-circle"></i>
                                                                </Button>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </>)}) : 
                                        <>
                                            <div className="card Tariff__BorderB">
                                    <div className="card-body">
                                     
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label">Office</label>
                                                            <input className="form-control cm-input" type="text" disabled={disableEdit}
                                                            onChange={(e) => {
                                                                const newMarketCode = e.target.value;
                                                                const updatedTourCode = [{ ...tourCode, marketCode: newMarketCode }];
                                                                commonOnChange('tourCode', updatedTourCode);
                                                            }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label">Adult</label>
                                                            <input className="form-control cm-input" type="text" disabled={disableEdit}
                                                            onChange={(e) => {
                                                                const tourCodes = e.target.value;
                                                                const updatedTourCodes = [{ ...tourCode, tourCodes: tourCodes, passengerType:`ADULT`}];
                                                                commonOnChange('tourCode', updatedTourCodes);
                                                            }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label">Child</label>
                                                            <input className="form-control cm-input" type="text" disabled={disableEdit}
                                                            onChange={(e) => {
                                                                const tourCodes = e.target.value;
                                                                const updatedTourCodes =[{ ...tourCode, tourCodes: tourCodes, passengerType:`CHILD`}];
                                                                commonOnChange('tourCode', updatedTourCodes);
                                                            }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label">Infant</label>
                                                            <input className="form-control cm-input" type="text" disabled={disableEdit}
                                                            onChange={(e) => {
                                                                const tourCodes = e.target.value;
                                                                const updatedTourCodes = [{ ...tourCode, tourCodes: tourCodes, passengerType:`INFANT`}];
                                                                commonOnChange('tourCode', updatedTourCodes);
                                                            }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                    <label className="cm-head-label"></label>
                                                        <div className='setD-flex'>
                                                            <Button className="BulkUpdate__removeBtn " disabled={true}><i className="fas fa-trash-alt"></i></Button>
                                                            <Button className='BulkUpdate__AddBtn' disabled={disableEdit} 
                                                            onClick={()=>{
                                                                    if(prixDetailsLocal?.tourCode?.length == 0){commonOnChange('tourCode',[tourCode,tourCode])}}}><i className="fas fa-plus-circle"></i></Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                        </>
                                            }
                                    </div>
                                </div>
                                
                            </TabPanel>
                        </TabContext>
                        {disableEdit ? null : <Box className="cm-card-edit" sx={{ background: '#E1F0FF', padding: '16px', mb: '0' }}>
                            <button className="cm-secondary-btn mr-2" type="button" onClick={()=>setDisableEdit(true)}>Cancel</button>
                            <button className="cm-primary-btn" onClick={()=>savePrix()}><i className='fa fa-save mr-1'></i> Save</button>
                        </Box>}
                    </Box>



                </div>
            </div >
        </ >
    )
}

export default NewPrix