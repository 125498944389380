import commonDataReducer from '../contractManagement/AppStore/reducer/commonDataReducer';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage'; 
// storage = localStorage or sessionStorage = sessionStorage
import { persistReducer } from 'redux-persist';
import loginReducers from './reducers/loginReducers';
import userReducers from './reducers/userReducers';
import tokenReducers from './reducers/tokenSaveReducers';
import agencyListReducer from './reducers/agencyListReducer';
import Agencysearchreducer from './reducers/Agencysearchreducer';
import Agentsearchreducer from './reducers/agentsearchReducer';
import Salessearchreducer from './reducers/Salessearchreducer';
import SaleListData from './reducers/SaleListReducer';
import allContractlistRecducer from './reducers/allContractlistRecducer';
import feesDatareducer from './reducers/feesDatareducer';

import searchFormReducer from '../../src/pages/bookingConfirmation/reducers/searchForm';
import PnrTokenReducer from '../../src/pages/bookingConfirmation/reducers/AftrePnrTokenReducer';
import AgentProfileReducer from '../../src/pages/bookingConfirmation/reducers/agent';
import FareMatrixReducer from '../../src/pages/bookingConfirmation/reducers/fareMatrix';
import FareOptionsReducer from '../../src/pages/bookingConfirmation/reducers/fareOptions';
import FlightBookingReducer from '../../src/pages/bookingConfirmation/reducers/flightBooking';
import InitTokenReducer from '../../src/pages/bookingConfirmation/reducers/initToken';
import ItinenaryReducer from '../../src/pages/bookingConfirmation/reducers/itinenary';
import MyFilesErrorReducer from '../../src/pages/bookingConfirmation/reducers/myFileError';
import MyFilesReducer from '../../src/pages/bookingConfirmation/reducers/myFiles';
import SavedQuotesReducer from '../../src/pages/bookingConfirmation/reducers/myQuotes';
import PenalatiesReducer from '../../src/pages/bookingConfirmation/reducers/penaltiesTab';
import InternalUserReducer from '../../src/pages/bookingConfirmation/reducers/user';
import consortiumReducer from '../pages/consortium/AppStore/Reducer/consortiumReducer';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['user' ,'userInfo','token','agencyList' ,'agecnySearchData' ,'SalessearchData','allContractData','feesDatareducer','commonDataReducer']
}

const rootReducer = combineReducers({
	consortiumReducer:consortiumReducer,
	commonDataReducer: commonDataReducer,
    user:loginReducers,
	userInfo:userReducers,
	token:tokenReducers,
	agencyList : agencyListReducer,
	agecnySearchData:Agencysearchreducer,
	agentSearchData:Agentsearchreducer,
	SalessearchData:Salessearchreducer,
	SaleListDataForget:SaleListData,
	allContractData:allContractlistRecducer,
	feesDatareducer:feesDatareducer,
	searchForm: searchFormReducer,
	pnrToken: PnrTokenReducer,
	agentProfile: AgentProfileReducer,
	fareMatrixResult:FareMatrixReducer,
	fareOptions:FareOptionsReducer,
	flightBooking:FlightBookingReducer,
	initToken: InitTokenReducer,
	itinenaries: ItinenaryReducer,
	myFile:MyFilesReducer,
  	myFileError:MyFilesErrorReducer,
	savedQuotes: SavedQuotesReducer,
	Penalaties: PenalatiesReducer,
	internalUser: InternalUserReducer
});


export default persistReducer(persistConfig,rootReducer);
